/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // bootstrap sub nav on mobile
        var $menuDropDownArrow = $(document.createElement('span'));
        $menuDropDownArrow.addClass('arrow').text('+');
        $menuDropDownArrow.click(function () {
           var txt = $(this).text() == '+' ? '-' : '+';
           $(this).text(txt);
           $(this).parent().find('> .dropdown-menu').slideToggle(500);
        });
        $('.navbar-nav .dropdown-menu').before($menuDropDownArrow);

        // header banner
        if($('.banner-container').length) {

          $('.banner').slick({
            prevArrow:"<img class='a-left control-c prev slick-prev' src='/wp-content/themes/wsf/dist/images/slide-arrow.png'>",
            nextArrow:"<img class='a-right control-c next slick-next' src='/wp-content/themes/wsf/dist/images/slide-arrow.png'>",
            dots: true
          });

          $(window).resize(function() {
            $('.banner').slick('resize');
          });
        }

        // gallery filter dropdown
        $('.filter-dropdown .dropdown-item').click(function(event) {
          $('.filter-dropdown .dropdown-toggle').text($(this).text());
        });

        // resizing the gallery items based on width
        function galleryItemSize() {
          $('.gallery__item').height(($('.gallery__item').width()) * 0.68);
        }

        galleryItemSize();

        $(window).resize(function(event) {
          // galleryItemSize();
        });

        // gallery grid
        // init Isotope
        $(window).load(function() {
          $gallery = $('.gallery').isotope({
            itemSelector: '.grid-sizer', // use a separate class for itemSelector, other than .col-
            // percentPosition: true
            // masonry: {
            //   columnWidth: '.grid-sizer'
            // }
          });
        });

        function filterGrids(filterValue) {
          $gallery.isotope({ filter: filterValue });
        }

        // gallery filter buttons
        $('.filter-nav-buttons button').click(function(event) {
          $('.filter-nav-buttons button').removeClass('active');
          $(this).addClass('active');
          var filterValue = $( this ).attr('data-filter');
          filterGrids(filterValue);
        });

        // gallery filter dropdown
        $('.filter-dropdown .dropdown-item').click(function(event) {
          var filterValue = $( this ).attr('data-filter');
          filterGrids(filterValue);
        });






      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // lightbox
        $('.swipebox').swipebox();
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_service': {

      init: function() {
        $('.services-slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.services-slider-nav'
        });
        $('.services-slider-nav').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          asNavFor: '.services-slider',
          arrows: false,
          dots: false,
          centerMode: false,
          focusOnSelect: true
        });

        function resizeSliderNav() {
          $('.services-slider-nav__item').height(($('.services-slider-nav__item').width()) * 0.8);
        }

        resizeSliderNav();

        $(window).resize(function(event) {
          resizeSliderNav();
        });

      }

    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // google map
        function initMap(){
          var myLatLng = {lat: -37.810114, lng: 175.329265 };
          var map = new google.maps.Map(document.getElementById('map'), {
            center: myLatLng,
            zoom: 15,
            scrollwheel: false,
            disableDefaultUI: true,
            mapTypeId:google.maps.MapTypeId.ROADMAP
          });
          var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            icon: '/wp-content/themes/wsf/dist/images/map-icon.png'
          });

          var contentString = '<div id="content"> ' +
                              '<h5 id="firstHeading" class="firstHeading">Waikato Steel Fabricators</h5></hr >' +
                              '<p>Address: 89 Riverlea Road, Hamilton</p>'+
                              '<p>Phone: <a href="tel:078564884">07 856 4884</a></p>' +
                              '<p>Email: <a href="mailto:tenders@wsf.co.nz">tenders@wsf.co.nz</a></p>' +
                              '</div>';

          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });
          marker.addListener('click', function(){
              infowindow.open(map, marker);
          });
          google.maps.event.addDomListener(window, 'resize', function(){
            var center = map.getCenter();
            google.maps.event.trigger(map,"resize");
            map.setCenter(center);
          });

        }


        initMap();



      }
    }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
